import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import "../i18n.js";
import "../style/index.css";
import "../proxy.js";
import { ObfuscateLayout } from "../components/obfuscate";

var Home = React.lazy(() => import("./home.js"));
var Error = React.lazy(() => import("./error.js"));

function App() {
  return (
    <>
      <ObfuscateLayout />
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<></>}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<></>}>
              <Error />
            </Suspense>
          }
        />
      </Routes>
    </>
  );
}

export default App;
